export const environment = {
  production: false,
  backendURL: 'https://us-central1-testnet-credit-manager.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyBpTg62kufiDegNGeOZ5JbBrh-T-D5IIJ4',
    authDomain: 'testnet-credit-manager.firebaseapp.com',
    projectId: 'testnet-credit-manager',
    storageBucket: 'testnet-credit-manager.appspot.com',
    messagingSenderId: '562978836228',
    appId: '1:562978836228:web:39ef9981da9d9328c44a51'
  }
};
